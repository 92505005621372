import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthRequest, AuthResponse } from '../models/auth-request.model';
import { Observable } from 'rxjs';
import {
  RefreshTokenRequest,
  RefreshTokenResponse,
} from '../models/refresh-token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = environment.webApi + '/api/auth';

  constructor(private http: HttpClient) {}

  signIn(authRequest: AuthRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(this.url + '/sign-in', authRequest);
  }

  refreshToken(refreshTokenRequest: RefreshTokenRequest): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(this.url + '/refresh-token', refreshTokenRequest);
  }
}
