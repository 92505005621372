import { Menu } from '../models/menu.model';

export enum MenuItemsEnum {
	Reference_Docs = 10,
	User_Management = 20
}

export const menuItems = [
	new Menu(
		MenuItemsEnum.Reference_Docs,
		`File Manager`,
		'/file-manager',
		null,
		'folder',
		null,
		null,
		false,
		0
	),
	new Menu(
		MenuItemsEnum.User_Management,
		`User Management`,
		'/users',
		null,
		'group',
		null,
		null,
		false,
		0
	)
];
