import { MsgBannerDialogComponent } from './components/msg-banner/msg-banner-dialog/msg-banner-dialog.component';
import { MsgBannerComponent } from './components/msg-banner/msg-banner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LockColumnComponent } from './components/lock-column/lock-column.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AppendCharFront } from './pipes/append-char-front.pipe';
import { CamelToTextPipe } from './pipes/camel-to-text.pipe';
import { DecimalNoCommaPipe } from './pipes/decimal-no-comma.pipe';
import { FilterByIdPipe } from './pipes/filter-by-id.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { DragDropDirective } from './directives/drag-drop-directive';
import { UtilsService } from '../core/services/utils.service';
import { SplitRequestService } from '../core/services/split-request.service';
import { UppercaseSpecificWordPipe } from './pipes/uppercase-specific-word.pipe';
import { DialogSelectorComponent } from './components/dialog-selector/dialog-selector.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { SelectAutocompleteComponent } from './components/mat-select-autocomplete/select-autocomplete.component';
import { ResponsiveStepperComponent } from './components/responsive-stepper/responsive-stepper.component';
import { DialogSelectorService } from './components/dialog-selector/services/dialog-selector.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { CustomFiltersComponent } from './components/custom-filters/custom-filters.component';
import { TableRowDialogComponent } from './components/table-row-dialog/table-row-dialog.component';
import { ErrorHandlerModule } from './errors/error-handler.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { UpperCaseInputDirective } from './directives/input-uppercase.directive';
import {UserRoleDirective} from "./directives/user-role.directive";
import { DateFormatPipe } from './pipes/date-format.pipe';

@NgModule({
  imports: [
    NgxMaskDirective, 
    NgxMaskPipe,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatStepperModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDividerModule,
    MatBadgeModule,
    NgScrollbarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    ErrorHandlerModule
  ],
	exports: [
		RouterModule,
		FlexLayoutModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatStepperModule,
		ClipboardModule,
		MatTooltipModule,
		MatDividerModule,
		FormsModule,
		ReactiveFormsModule,
		MatBadgeModule,
		MsgBannerComponent,
		DragDropDirective,
		UpperCaseInputDirective,
		UserRoleDirective,
		SelectCheckAllComponent,
		SelectAutocompleteComponent,
		ResponsiveStepperComponent,
		NgScrollbarModule,
		MatProgressSpinnerModule,
		MatChipsModule,
		BreadcrumbComponent,
		LockColumnComponent,
		CustomTableComponent,
		CustomFiltersComponent,
		TableRowDialogComponent,
		ErrorHandlerModule,
		DateFormatPipe
	],
  declarations: [
    MsgBannerDialogComponent,
    DialogSelectorComponent,
    MsgBannerComponent,
    DragDropDirective,
    UpperCaseInputDirective,
    UserRoleDirective,
    SelectCheckAllComponent,
    SelectAutocompleteComponent,
    ConfirmDialogComponent,
    ResponsiveStepperComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    BreadcrumbComponent,
    LockColumnComponent,
    // Pipes
    AppendCharFront,
    CamelToTextPipe,
    DecimalNoCommaPipe,
    FilterByIdPipe,
    SortPipe,
    YesNoPipe,
    UppercaseSpecificWordPipe,
    CustomTableComponent,
    CustomFiltersComponent,
    TableRowDialogComponent,
    DateFormatPipe,
  ],
  providers: [
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    DialogSelectorService,
    UtilsService,
    SplitRequestService,
    provideNgxMask()
  ],
})
export class SharedModule {}
