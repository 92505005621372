import {Component, HostListener, Injector} from '@angular/core';
import { AppSettings, Settings } from './app.settings';
import { Subject } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart, Params,
  Router,
} from '@angular/router';
import { LoadingService } from './core/services/loading.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = `Reference Documents/Training`;
  public settings: Settings;

  isIframe: boolean;
  loginDisplay: boolean;
  isLoading: Subject<boolean> = this.loadingService.isLoading;
  public showBackToTop = false;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private appSettings: AppSettings,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.isIframe = window !== window.parent && !window.opener;
    this.settings = this.appSettings.settings;

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params && params['initializeProcess']) {
        const initializeProcess = JSON.parse(params['initializeProcess']);
        if (initializeProcess) {
          this.cookieService.delete('authResponse');
          this.router.navigateByUrl('/login');
        } 
      }
    });
  }

  ngOnDestroy() {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    $event.target.documentElement.scrollTop > 100
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  public goToTop() {
    const elmnt = document.getElementById('top');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }
}
