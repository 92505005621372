import { AppConstants } from './../../../../app.constants';
import { UserInterfaceMessage } from './../models/user-interface-message.model';
import { EMPTY, Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, catchError } from 'rxjs/operators';
import { DateTime } from 'luxon';

export class CacheModel {
	content: any;
	expiration: Date;

	constructor(timeout: number = 30, content: any) {
		this.content = content;
		this.expiration = DateTime.now().plus({ minutes: timeout }).toJSDate();
	}
}

@Injectable({
	providedIn: 'root'
})
export class UserInterfaceMessageService {
	private schema: string;

	constructor(
		private http: HttpClient,
		private constants: AppConstants
	) {
		/** Set default schema */
		// this.setSchema(constants.FEC_SCHEMA)
	}

	messageCache: Map<number, CacheModel> = new Map();

	getMessage(userInterfaceMsgId: number): Observable<UserInterfaceMessage> {
		if (this.availableCache(userInterfaceMsgId)) {
			return this.messageCache.get(userInterfaceMsgId)?.content;
		}

		this.messageCache.set(userInterfaceMsgId, new CacheModel(
			this.constants.CACHE_TIMEOUT,
			this.http.get<any>(
				this.getRootUrl() + userInterfaceMsgId
			).pipe(
				shareReplay(1),
				catchError(err => {
					console.log(err);
					this.messageCache.delete(userInterfaceMsgId);
					return EMPTY;
				})
			)
		));

		return this.messageCache.get(userInterfaceMsgId)?.content;
	}

	getMessageSync(userInterfaceMsgId: number): Promise<UserInterfaceMessage> {
		if (this.availableCache(userInterfaceMsgId)) {
			return this.messageCache.get(userInterfaceMsgId)?.content;
		}

		this.messageCache.set(userInterfaceMsgId, new CacheModel(
			this.constants.CACHE_TIMEOUT,
			this.http.get<any>(
				this.getRootUrl() + userInterfaceMsgId
			).pipe(
				shareReplay(1),
				catchError(err => {
					console.log(err);
					this.messageCache.delete(userInterfaceMsgId);
					return EMPTY;
				})
			).toPromise()
		));

		return this.messageCache.get(userInterfaceMsgId)?.content;
	}

	availableCache(userInterfaceMsgId: number) {
		return this.messageCache.get(userInterfaceMsgId) && this.messageCache.get(userInterfaceMsgId)?.expiration > new Date();
	}

	private getRootUrl() {
		return environment.webApi + `/gateway/${this.schema}/messages/`;
	}

	public setSchema(schema: string): void {
		this.schema = schema;
	}
}
