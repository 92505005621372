import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConstants {
  public CACHE_TIMEOUT = 30;
  public PAGE_SIZE = 50;

  public MIN_LENGTH_CHAR_SEARCH = 3;

  public EXCEL_FILE_NAME = 'TEMPLATE';
}
