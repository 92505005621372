import { AppConstants } from 'src/app/app.constants';
import { filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MsgBannerService } from 'src/app/shared/components/msg-banner/msg-banner.service';
import { AutoCompleteRefModel } from '../models/autocomplete-ref.model';
import { AutocompleteModel } from '../models/autocomplete.model';

@Injectable()
export class SelectAutocompleteService {
  constructor(
    private msgBanner: MsgBannerService,
    private constants: AppConstants
  ) {}

  public addAutocompleteEvent(...refModels: AutoCompleteRefModel[]) {
    refModels.forEach((refModel: AutoCompleteRefModel) => {
      refModel.inputSearch
        .pipe(
          filter(
            (res: string) =>
              (!refModel.totalElements ||
                (refModel &&
                  refModel.totalElements > this.constants.PAGE_SIZE)) &&
              (res === '' ||
                (res && res.length >= this.constants.MIN_LENGTH_CHAR_SEARCH))
          ),
          distinctUntilChanged(),
          switchMap((value) => refModel.fetchCallback(value))
        )
        .subscribe({
          next: (data: any) => {
            if (refModel.inputSearch.value === '') {
              refModel.totalElements = data.totalElements;
            }
            this.handleAutocompleteFetch(data.content, refModel);
          },
          error: (error) => {
            this.msgBanner.handleUnexpectedErr(error);
          },
        });
    });
  }
  public handleAutocompleteFetch(
    data: AutocompleteModel[],
    ref_params: AutoCompleteRefModel
  ) {
    if (data === undefined) {
      this.msgBanner.handleUnexpectedErr();
      ref_params.options = [];
    } else {
      const dropdownSelectedOptions: any[] = [];
      if (ref_params.formControl.value) {
        let values: string[] = Array.isArray(ref_params.formControl.value)
          ? ref_params.formControl.value
          : [ref_params.formControl.value];

        if (ref_params.options?.length) {
          values.forEach((element) => {
            const selectedValue = ref_params.options.find(
              (item) => item.value === element
            );
            dropdownSelectedOptions.push(selectedValue);
          });
        } else {
          values.forEach((element) => {
            let item = data.find((item) => item.value === element);
            dropdownSelectedOptions.push(item ? item : {value: element, display: element});
          });
        }
      }
      dropdownSelectedOptions.forEach((selectedOption) => {
        if (!data.find((item) => item.value === selectedOption.value)) {
          data.push(selectedOption);
        }
      });
      ref_params.options = data;
    }
  }

  public resetAutocomplete(...refModels: AutoCompleteRefModel[]) {
    refModels.forEach((refModel: AutoCompleteRefModel) => {
      refModel.totalElements = null;
      refModel.inputSearch.next('###');
      refModel.inputSearch.next('');
    });
  }
}
