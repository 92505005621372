import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthResponse } from '../models/auth-request.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import {MsgBannerService} from "../../shared/components/msg-banner/msg-banner.service";

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  requestsInProcess = 0;
  isRefreshing = false;
  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private msgBanner: MsgBannerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let noSpinnerFlag = false;
    if (req.headers.has(environment.noLoadingSpinnerHeader) || req.url.includes('json')) {
      noSpinnerFlag = true;
      req.headers.delete(environment.noLoadingSpinnerHeader);
    } else {
      this.requestsInProcess++;
      setTimeout(() => {
        this.spinner.show();
      }, 0);
    }
    // Sets the token on the request headers
    const authData = this.cookieService.get('authResponse')
      ? JSON.parse(this.cookieService.get('authResponse')) : null;
    if (authData && !req.url.includes('refreshToken')) {
      req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${authData?.accessToken}`)});
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          if (authData) {
            req = req.clone({ headers: req.headers.delete('Authorization') });
            return this.handle403Error(req, next, authData);
          } else {
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              disableClose: true,
              data: {
                message:
                  `An authentication exception occurred. Please log in again.`,
                confirmationRequired: false,
              },
            });
            return throwError(() => new Error());
          }
        }

        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(
          `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
        );
        return throwError(() => error);
      }),
      finalize(() => {
        if (!noSpinnerFlag) {
          this.requestsInProcess--;
          if (this.requestsInProcess === 0) {
            this.spinner.hide();
          }
        }
      })
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler, authData: AuthResponse) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.authService
        .refreshToken({ refreshToken: authData?.refreshToken })
        .pipe(
          switchMap((res) => {
            this.isRefreshing = false;
            if (res) {
              authData.accessToken = res?.accessToken;
              this.cookieService.set('authResponse', JSON.stringify(authData));
              request = request.clone({ headers: request.headers.set(
                'Authorization', `Bearer ${res?.accessToken}`)});
            }
            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;
            if ((error.status === 400 || error.status === 500) && error.url.endsWith('/refresh-token')) {
              this.cookieService.delete('authResponse');
              this.router.navigate(['/login']);

              this.msgBanner.addMessage('Session expired. Please log in again.');
            }

            if (error.status == 403) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                  title: `Session expired`,
                  message:
                    `Please log in again.`,
                  confirmationRequired: false,
                },
              });
              dialogRef.afterClosed().subscribe((dialogResult) => {
                if (dialogResult) {
                  this.cookieService.delete('authResponse');
                  this.router.navigate(['/login']);
                  return;
                }
              });

            }
            return throwError(() => null);
          })
        );
    }

    return next.handle(request);
  }
}
